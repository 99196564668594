.header-wrapper {
    width: 100%;

    padding: 0 20px;
    padding-top: 40px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    background-color: transparent;

    & .header-sub-wrapper {
        width: 100%;
        height: 60px;
        display: flex;
        padding: 10px 50px 10px 40px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        border-radius: 20px;
        background: var(--color-white);

        & .header-logo {
            width: 68px;
            height: 24px;

            & > a {
                text-decoration: none;
                color: var(--color-black);

                & > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        & .header-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            gap: 100px;
            padding-left: 0;

            & .header-menu-item {
                margin: 0 10px;
                font-size: 18px;
                font-weight: 600;
                color: var(--color-black);
                list-style: none;

                & > a {
                    text-decoration: none;
                    color: var(--color-black);
                }

                & > a:hover {
                    color: var(--color-link-hover);
                }

                & > a.active {
                    color: var(--color-link-hover);
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .header-wrapper {
        & .header-sub-wrapper {
            & .header-menu {
                gap: 70px;
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .header-wrapper {
        padding: 0 10px;
        padding-top: 40px;
        user-select: none;

        & .header-sub-wrapper {
            padding: 0px 12px 0px 20px;

            & .header-logo {
                width: 56.667px;
                height: 20px;
            }

            & .header-dropdown-menu {
                display: flex;
                flex-direction: column;
                position: absolute;

                top: 80px;
                left: 0;
                right: 0;

                background-color: var(--color-white);
                border-radius: 0 0 20px 20px;
                overflow: hidden;
                z-index: 999;

                margin: 0 10px;
                padding: 0 20px 5px;

                max-height: 0;
                transition: max-height 1s ease;

                & .header-dropdown-menu-item {
                    padding: 15px 0;
                    color: var(--color-black);
                    list-style: none;

                    & > a {
                        text-decoration: none;
                        color: inherit;
                        display: block;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: -0.2px;
                    }
                }

                & .header-dropdown-menu-inner {
                    padding-top: 40px;
                }
            }

            & .header-dropdown-menu.show {
                max-height: 500px;
                transition: max-height 1s ease;
            }
        }

        & .header-menu-icon {
            display: block;
            transition: 0.5s;
        }

        & .header-menu-icon.hide {
            display: none;
            transition: 0.5s;
        }
    }
}
