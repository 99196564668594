@import url('../portfolio/index.css');

.information-wrapper {
    width: 100%;
    color: var(--color-white);
    padding: 0 60px;
    box-sizing: border-box;

    & .information-title {
        padding-top: 200px;
        display: flex;
        justify-content: space-between;

        & > p {
            flex: 1;
        }

        & > p:first-child {
            font-size: 50px;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: -2.4px;
        }

        & > p:last-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: -0.16px;
        }
    }

    & .information-desc {
        padding: 100px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        & > p:first-child {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: -0.24px;
        }
    }

    & .divider {
        width: 100%;
        height: 1px;
        margin: 20px 0;
        opacity: 0.4;
        background-color: var(--color-white);
    }

    & .information-content {
        display: flex;
        gap: 240px;

        & > p {
            display: flex;
            flex-direction: column;
            gap: 4px;

            & > span:first-child {
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: -0.16px;
            }

            & > span:last-child {
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: -0.16px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .information-wrapper {
        padding: 0 40px;

        & .information-title {
            & > p:first-child {
                font-size: 40px;
                line-height: 55px;
            }

            & > p:last-child {
                font-size: 14px;
                line-height: 24px;
            }
        }

        & .information-desc {
            & > p:first-child {
                font-size: 20px;
                line-height: 30px;
            }
        }

        & .information-content {
            gap: 120px;
        }
    }
}
@media screen and (max-width: 745px) {
    .information-wrapper {
        padding: 0 20px;

        & .information-title {
            flex-direction: column;
            gap: 16px;

            & > p:first-child {
                font-size: 30px;
                line-height: 40px;
            }

            & > p:last-child {
                font-size: 12px;
                line-height: 20px;
            }
        }

        & .information-desc {
            padding: 40px 0;

            & > p:first-child {
                font-size: 16px;
                line-height: 26px;
            }
        }

        & .information-content {
            flex-direction: column;
            gap: 20px;
        }
    }
}

.portfolio-list-wrapper {
    & .portfolio-list-sub-wrapper {
        & .portfolio-list-one-detail {
            margin-top: 20px;

            & img {
                width: 100%;
                height: 100%;
                aspect-ratio: 16 / 9;
                object-fit: cover;
                max-width: 100%;
                border-radius: 16px;
            }
        }

        & .portfolio-list-two-detail {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-top: 20px;

            & > img {
                width: 100%;
                height: 100%;
                aspect-ratio: 16 / 16;
                object-fit: cover;
                max-width: 100%;
                border-radius: 16px;
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .portfolio-list-wrapper {
        & .portfolio-list-sub-wrapper {
            & .portfolio-list-two-detail {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                & > img {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                    max-width: 100%;
                    border-radius: 16px;
                }
            }
        }
    }
}

.other-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: var(--color-white);

    & .other-title {
        padding: 0 60px;
        box-sizing: border-box;
        font-size: 64px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -1.92px;
    }

    & .other-images {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        gap: 20px;

        & .other-image {
            position: relative;
            cursor: pointer;

            & img {
                width: 100%;
                height: auto;
                object-fit: cover;
                max-width: 100%;
                border-radius: 16px;
            }

            & .other-image-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.6) 5%,
                    rgba(0, 0, 0, 0) 100%
                );
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 25px;
                box-sizing: border-box;

                & p {
                    color: var(--color-white);
                }

                & > div {
                    display: flex;
                    flex-direction: column;

                    & .other-image-title {
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 34px;
                        letter-spacing: -0.24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .other-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: var(--color-white);
        padding-bottom: 100px;

        & .other-title {
            padding: 0 20px;
            box-sizing: border-box;
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: -0.96px;
        }

        & .other-images {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 10px;

            & .other-image {
                position: relative;
                cursor: pointer;

                & img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    max-width: 100%;
                    max-height: 528px;
                    border-radius: 16px;
                }

                & .other-image-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.6) 5%,
                        rgba(0, 0, 0, 0) 100%
                    );
                    padding: 25px;
                    box-sizing: border-box;

                    & p {
                        color: var(--color-white);
                    }

                    & > div {
                        display: flex;
                        flex-direction: column;

                        & .other-image-title {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                        }
                    }
                }
            }
        }
    }
}
