.footer-wrapper {
    width: 100%;
    height: auto;
    padding: 20px;

    background: url('../../assets/background_big4.webp');
    background-size: 100vw 100vh;
    background-position: center;
    background-repeat: repeat-y;

    & .footer-sub-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 56px;

        padding: 32px 540px;
        align-self: stretch;

        border-radius: 20px;
        background-color: var(--color-white);

        & .footer-text-box {
            width: 460px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            & > h3 {
                font-size: 100px;
                font-weight: 600;
                line-height: 100px;
                letter-spacing: -2px;
                margin: 0;

                &:nth-of-type(1),
                &:nth-of-type(2) {
                    font-family: 'Playfair Display';
                    font-style: italic;
                }

                &:nth-of-type(2) {
                    text-align: right;
                }

                &:nth-of-type(3) {
                    text-align: center;
                }
            }
        }

        & .footer-link-box {
            display: flex;
            gap: 40px;

            & > a {
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: -0.18px;
                text-decoration: none;
            }
        }

        & .footer-info-box {
            display: flex;
            flex-direction: column;
            gap: 16px;

            & .footer-info {
                display: flex;
                gap: 16px;
                white-space: nowrap;

                & > p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.14px;

                    & > span {
                        font-weight: 700;
                    }
                }
            }

            & .footer-content {
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: -0.14px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .footer-wrapper {
        & .footer-sub-wrapper {
            padding: 32px 350px;
        }
    }
}
@media screen and (max-width: 745px) {
    .footer-wrapper {
        width: 100%;
        padding: 10px;

        & .footer-sub-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            padding: 32px 0px;

            & .footer-text-box {
                width: 280px;

                display: flex;
                flex-direction: column;
                justify-content: center;

                & > h3 {
                    font-size: 64px;
                    font-weight: 600;
                    line-height: 60px;
                    letter-spacing: -1.92px;
                    margin: 0;
                }
            }

            & .footer-link-box {
                display: flex;
                width: 280px;
                justify-content: space-between;
                gap: 0;

                & > a {
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: -0.13px;
                }
            }

            & .footer-info-box {
                display: flex;
                flex-direction: column;
                gap: 16px;

                & .footer-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 6px;
                    white-space: nowrap;

                    & > p {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: -0.13px;
                        margin: 0;

                        & > span {
                            font-weight: 700;
                        }
                    }
                }

                & .footer-content {
                    width: 280px;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: -0.13px;
                }
            }
        }
    }
}
