.container-long {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.container-center {
    align-items: center;
    flex-direction: column;
}

.container-normal {
    width: 100%;
    min-height: 100vh;
    height: auto;
    box-sizing: border-box;
    background: url('../../assets/background_big4.webp');
    background-size: 100vw 100vh;
    background-position: center;
    background-repeat: repeat-y;
}
