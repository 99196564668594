.portfolio-title-wrapper {
    width: 100%;
    height: 100%;

    padding-top: 260px;

    & .portfolio-title-sub-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 60px;

        & .portfolio-title {
            color: var(--color-white);

            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 36px;

            & > h1 {
                margin: 0;
                font-size: 140px;
                font-weight: 600;
                line-height: 140px;
                letter-spacing: -4.2px;
            }

            & > p {
                margin: 0;
                font-size: 28px;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: -0.28px;
            }
        }

        & .portfolio-category-btn {
            display: flex;
            gap: 8px;

            & .btn {
                display: flex;
                height: 40px;
                padding: 0px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 20px;
                border: 1px solid #fff;
                background-color: transparent;
                color: var(--color-white);
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: -0.16px;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    background-color: var(--color-white);
                    color: var(--color-black);
                }
            }

            & .active {
                background-color: var(--color-white);
                color: var(--color-black);
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .portfolio-title-wrapper {
        width: 100%;
        height: 100%;

        padding-top: 200px;

        & .portfolio-title-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 24px;
            box-sizing: border-box;
            gap: 48px;

            & .portfolio-title {
                color: var(--color-white);

                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 20px;

                & > h1 {
                    margin: 0;
                    font-size: 80px;
                    font-weight: 600;
                    line-height: 80px;
                    letter-spacing: -2.4px;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                & > p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: -0.16px;
                    text-align: start;
                }
            }

            & .portfolio-category-btn {
                display: flex;
                gap: 8px;

                & .btn {
                    display: flex;
                    height: 32px;
                    padding: 0px 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 20px;
                    border: 1px solid #fff;
                    background-color: transparent;
                    color: var(--color-white);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: -0.14px;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        background-color: var(--color-white);
                        color: var(--color-black);
                    }
                }

                & .active {
                    background-color: var(--color-white);
                    color: var(--color-black);
                }
            }
        }
    }
}

.portfolio-list-wrapper {
    width: 100%;
    padding: 140px 20px;
    box-sizing: border-box;

    & .portfolio-list-sub-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        & .portfolio-list-one {
            width: 100%;
            margin-top: 20px;
            position: relative;
        }

        & .portfolio-list-two {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;

            & > div {
                flex: 1;
                position: relative;
            }
        }

        & .portfolio-list-one,
        & .portfolio-list-two {
            position: relative;
            cursor: pointer;
            margin-top: 20px;

            & > div {
                position: relative;
            }

            & .content-box {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 20px;

                color: var(--color-black);
                opacity: 0;
                transition: all 0.3s;

                & > h3 {
                    width: 100%;
                    margin: 0;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 38px;
                    letter-spacing: -0.96px;
                }

                & > p {
                    margin-top: 6px;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: -0.16px;
                }
            }

            & > img {
                width: 100%;
                height: auto;
                aspect-ratio: 16 / 9;
                max-width: 100%;
                border-radius: 16px;
            }

            & .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(221, 221, 221, 0.75);
                opacity: 0;
                transition: opacity 0.3s;
                pointer-events: none;
                border-radius: 16px;
            }

            &:hover .overlay {
                opacity: 1;
                transition: opacity 0.3s;
            }

            & > div:hover .overlay {
                opacity: 1;
                transition: opacity 0.3s;
            }

            & .content-box.active {
                opacity: 1;
                transition: all 0.3s;
            }
        }

        & .category {
            position: absolute;
            position: absolute;
            left: 50%;
            top: 45%;
            transform: translate(-45%, -50%);
            text-align: center;
            display: flex;
            height: 40px;
            padding: 0px 20px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 20px;
            background: var(--color-black);
            color: var(--color-white);
            backdrop-filter: blur(10px);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: -0.16px;
            transition: all 0.3s;
            opacity: 0;
        }

        & .category.active {
            opacity: 0;
            transition: all 0.3s;
        }
    }
}

@media screen and (max-width: 745px) {
    .portfolio-list-wrapper {
        width: 100%;
        padding: 0 20px;
        padding-top: 20px;
        padding-bottom: 100px;

        & .portfolio-list-sub-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            & .portfolio-list-one {
                width: 100%;
                margin-top: 20px;
                position: relative;
            }

            & .portfolio-list-two {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                & > div {
                    flex: 1;
                    position: relative;
                }
            }

            & .portfolio-list-one,
            & .portfolio-list-two {
                position: relative;

                & .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.6) 5%,
                        rgba(0, 0, 0, 0) 100%
                    );
                    opacity: 1;
                    transition: opacity 0.3s;
                    pointer-events: none;
                    border-radius: 16px;
                }

                & > div {
                    position: relative;
                }

                & .content-box {
                    position: absolute;
                    top: 20px;
                    margin-left: 20px;
                    width: 100%;
                    text-align: left;
                    height: 0;
                    color: #fff;
                    opacity: 1;
                    transition: all 0.3s;

                    & > h3 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: -0.16px;
                        padding-bottom: 0px;
                    }

                    & > p {
                        margin-top: 4px;
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: -0.13px;
                    }
                }

                & .content-box.active {
                    opacity: 1;
                    transition: all 0.3s;
                }

                &:hover .overlay {
                    opacity: 1;
                    transition: opacity 0.3s;
                }

                & > div:hover .overlay {
                    opacity: 1;
                    transition: opacity 0.3s;
                }

                & > img {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                    max-width: 100%;
                    border-radius: 16px;
                }

                & > div > img {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                    max-width: 100%;
                    border-radius: 16px;
                }
            }

            & .category {
                position: absolute;
                top: 20px;
                left: 20px;
                display: flex;
                height: 24px;
                padding: 0px 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                border-radius: 20px;
                background: var(--color-white);
                backdrop-filter: blur(10px);
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: -0.13px;
                transition: all 0.3s;
                opacity: 0;
            }

            & .category.active {
                opacity: 0;
                transition: all 0.3s;
            }
        }
    }
}
