.contact-title-wrapper {
    width: 100%;
    padding-top: 320px;

    & .contact-title-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .contact-title {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 36px;
            color: var(--color-white);

            & > h2 {
                max-width: 738px;
                margin: 0;
                & > img {
                    width: 100%;
                    height: auto;
                }
            }

            & > p {
                font-size: 28px;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: -0.28px;
            }
        }

        & .contact-button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 0px 32px;
            margin-top: 60px;
            box-sizing: border-box;
            height: 52px;
            border-radius: 60px;
            background: #fff;
            border: none;
            color: var(--color-black);
            cursor: pointer;

            & > span {
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: -0.16px;
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .contact-title-wrapper {
        width: 100%;
        padding-top: 150px;

        & .contact-title-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 20px;
            box-sizing: border-box;

            & .contact-title {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 20px;
                color: var(--color-white);

                & > h2 {
                    max-width: 174px;
                    margin: 0;
                    & > img {
                        width: 100%;
                        height: auto;
                    }
                }

                & > p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: -0.16px;
                }
            }

            & .contact-button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                padding: 0px 12px 0px 16px;
                margin-top: 20px;
                box-sizing: border-box;
                height: 32px;
                border-radius: 60px;
                background: #fff;
                border: none;
                color: var(--color-black);
                cursor: pointer;

                & > span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: -0.14px;
                }

                & > svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.contact-form-wrapper {
    width: 100%;
    & .contact-form-box {
        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        color: var(--color-white);

        & .form {
            height: 165px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);

            & > p {
                display: flex;
                gap: 4px;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: -0.16px;

                & > span {
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: #ec0033;
                    border-radius: 50%;
                    margin-top: 5px;
                }
            }

            & > input,
            textarea {
                height: 52px;
                border: none;
                resize: none;
                border-radius: 0px;
                background: transparent;
                color: var(--color-white);
                font-size: 44px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: -1.32px;
            }

            & > textarea {
                resize: none;
                height: 104px;
            }

            & > input:focus,
            textarea:focus {
                outline: none;
            }

            & > input::placeholder,
            textarea::placeholder {
                color: var(--color-white);
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: -1.32px;
                opacity: 0.2;
            }
        }

        & .form.message {
            height: 200px;
        }

        & .form.agreement {
            display: flex;
            height: 100%;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 60px;
            padding-bottom: 20px;
            box-sizing: border-box;

            & .agreement-box {
                max-width: 669px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                & .check-box {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    & > span {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: -0.18px;
                    }

                    & > svg {
                        cursor: pointer;
                    }
                }

                & > p {
                    opacity: 0.6;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: -0.16px;
                }
            }

            & .send-box {
                width: 150px;
                height: 150px;
                flex-shrink: 0;
                background-color: #ec0033;
                border-radius: 50%;
                transition: background-color 0.3s;

                & > button {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    background: none;
                    color: var(--color-white);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: -0.16px;
                    cursor: pointer;
                    transition: color 0.3s;
                }
            }

            & .send-box.disable {
                width: 150px;
                height: 150px;
                background-color: #444;
                transition: background-color 0.3s;

                & > button {
                    color: #999;
                    cursor: not-allowed;
                    transition: color 0.3s;
                }
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .contact-form-wrapper {
        width: 100%;
        margin-top: 40px;
        & .contact-form-box {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            color: var(--color-white);

            & .form {
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 12px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);

                & > p {
                    display: flex;
                    gap: 4px;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: -0.13px;

                    & > span {
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: #ec0033;
                        border-radius: 50%;
                        margin-top: 2px;
                    }
                }

                & > input,
                textarea {
                    height: 26px;
                    border: none;
                    resize: none;
                    border-radius: 0px;
                    background: var(--color-black);
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                }

                & > textarea {
                    resize: none;
                    height: 40px;
                }

                & > input:focus,
                textarea:focus {
                    outline: none;
                }

                & > input::placeholder,
                textarea::placeholder {
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    opacity: 0.2;
                }
            }

            & .form.message {
                height: 120px;
            }

            & .form.agreement {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: unset;
                padding-top: 36px;
                box-sizing: border-box;

                & .agreement-box {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    & .check-box {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;

                        & > span {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                        }
                    }

                    & > p {
                        opacity: 0.6;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }

                & .send-box {
                    width: 100%;
                    height: 120px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background-color: unset;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;

                    & > button {
                        width: 120px;
                        height: 120px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        background: #ec0033;
                        border-radius: 50%;
                        color: var(--color-white);
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: -0.16px;
                        cursor: pointer;
                    }
                }

                & .send-box.disable {
                    width: 100%;
                    height: 120px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background-color: unset;
                    display: flex;
                    justify-content: flex-end;
                    transition: background-color 0.3s;
                    margin-top: 20px;

                    & > button {
                        width: 120px;
                        height: 120px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        border-radius: 50%;
                        font-size: 16px;
                        font-weight: 700;
                        background-color: #444;
                        line-height: 26px;
                        letter-spacing: -0.16px;
                        color: #999;
                        cursor: not-allowed;
                        transition: color 0.3s;
                    }
                }
            }
        }
    }
}

.contact-location-wrapper {
    width: 100%;
    height: 100%;
    padding: 100px 60px;
    box-sizing: border-box;

    & .contact-location-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        & .contact-location-text {
            max-width: 669px;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: var(--color-white);

            & > p {
                display: flex;
                gap: 20px;
            }

            & > p > span {
                font-size: 24px;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: -0.28px;
            }

            & > p > span > a {
                color: var(--color-white);
                text-decoration: underline;
            }

            & > p:first-of-type {
                font-size: 70px;
                font-weight: 600;
                line-height: 80px;
                letter-spacing: -2.4px;
            }
        }

        & .contact-location-map {
            flex: 1;
            height: 700px;
        }
    }
}

@media screen and (max-width: 745px) {
    .contact-location-wrapper {
        width: 100%;
        height: 100%;
        padding: 100px 20px;
        box-sizing: border-box;

        & .contact-location-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & .contact-location-text {
                max-width: 669px;
                height: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: var(--color-white);

                & > p {
                    display: flex;
                    gap: 10px;
                }

                & > p > span {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 40px;
                    letter-spacing: -0.28px;
                }

                & > p > span > a {
                    color: var(--color-white);
                    text-decoration: underline;
                }

                & > p:first-of-type {
                    font-size: 60px;
                    font-weight: 600;
                    line-height: 80px;
                    letter-spacing: -2.4px;
                }
            }

            & .contact-location-map {
                flex: 1;
                height: 100%;
                margin-top: 40px;
            }
        }
    }
}
