.App {
    width: 100%;
    height: 100vh;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-display: swap;
    src:
        local('Pretendard Black'),
        url('./assets/font/Pretendard/Pretendard-Black.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src:
        local('Pretendard ExtraBold'),
        url('./assets/font/Pretendard/Pretendard-ExtraBold.woff2')
            format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src:
        local('Pretendard Bold'),
        url('./assets/font/Pretendard/Pretendard-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-display: swap;
    src:
        local('Pretendard SemiBold'),
        url('./assets/font/Pretendard/Pretendard-SemiBold.woff2')
            format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src:
        local('Pretendard Medium'),
        url('./assets/font/Pretendard/Pretendard-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src:
        local('Pretendard Regular'),
        url('./assets/font/Pretendard/Pretendard-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src:
        local('Pretendard Light'),
        url('./assets/font/Pretendard/Pretendard-Light.woff2') format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-display: swap;
    src:
        local('Pretendard ExtraLight'),
        url('./assets/font/Pretendard/Pretendard-ExtraLight.woff2')
            format('woff2');
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-display: swap;
    src:
        local('Pretendard Thin'),
        url('./assets/font/Pretendard/Pretendard-Thin.woff2') format('woff2');
}

body {
    margin: 0;
    font-family: 'Pretendard', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* 변수 css 지정 */
:root {
    --color-white: #ffffff;
    --color-black: #000000;

    --color-link-hover: #ec0033;

    --color-background-bus: #a01634;
}

/* 글로벌 className */
.italic-text {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 500;
}

/* 스크롤바 커스텀 */
::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #ec0033;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--color-white);
}
