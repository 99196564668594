.carrer-title-wrapper {
    width: 100%;
    height: 100vh;

    & .carrer-title-box {
        width: 100%;
        height: 100%;
        color: var(--color-white);
        padding: 0 60px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;

        & > h2 {
            margin: 0;
            font-size: 140px;
            font-weight: 600;
            line-height: 140px;
            letter-spacing: -4.2px;
        }

        & > p {
            max-width: 960px;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 34px;
        }
    }
}

@media screen and (max-width: 745px) {
    .carrer-title-wrapper {
        width: 100%;
        height: 100%;
        padding-top: 156px;

        & .carrer-title-box {
            width: 100%;
            height: 100%;
            color: var(--color-white);
            padding: 0 20px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 16px;

            & > h2 {
                margin: 0;
                font-size: 44px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: -1.32px;
            }

            & > p {
                max-width: 960px;
                text-align: start;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}

.carrer-desired-wrapper {
    width: 100%;
    height: 100vh;
    & .carrer-desired-box {
        width: 100%;
        height: 100%;

        & > h2 {
            margin: 0;
            padding-left: 60px;
            box-sizing: border-box;
        }

        & .carrer-desired-circle {
            display: flex;
            position: relative;
            margin-top: 100px;

            & .circle {
                border: 2px solid var(--color-white);
                border-radius: 50%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32vw;
                height: 32vw;
                max-width: 640px;
                max-height: 640px;
                perspective: 1000px;

                & .content {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    backface-visibility: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: transform 0.6s;
                    color: var(--color-white);
                }

                & .content.front {
                    box-sizing: border-box;
                    border-radius: 50%;
                    & > p {
                        display: flex;
                        flex-direction: column;
                        gap: 40px;
                        text-align: center;

                        & > span:nth-of-type(1) {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 34px;
                            letter-spacing: -0.18px;
                            display: none;
                        }

                        & > span:nth-of-type(2) {
                            font-size: 44px;
                            font-weight: 700;
                            line-height: 48px;
                            letter-spacing: -1.32px;
                        }
                    }
                }

                & .content.back {
                    box-sizing: border-box;
                    border-radius: 50%;

                    & > p {
                        display: flex;
                        flex-direction: column;
                        gap: 40px;
                        text-align: center;

                        & > span:nth-of-type(1) {
                            font-size: 40px;
                            font-weight: 700;
                            line-height: 60px; /* 150% */
                            letter-spacing: -1.2px;
                        }

                        & > span:nth-of-type(2) {
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 34px; /* 188.889% */
                            letter-spacing: -0.18px;
                            width: 320px;
                        }
                    }
                }

                &:hover {
                    border: 2px solid var(--color-white);
                }
            }

            & .circle-img {
                position: absolute;
                pointer-events: none;
            }

            & .circle .back {
                transform: rotateY(180deg);
            }

            & .circle:hover .front {
                transform: rotateY(180deg);
            }

            & .circle:hover .back {
                transform: rotateY(360deg);
                background-color: var(--color-white);
                color: var(--color-black);
                border-radius: 50%;
            }

            & .circle:nth-child(1) {
                left: 60px;
            }

            & .circle:nth-child(1) .circle-img {
                position: absolute;
                bottom: -100px;
                left: -50px;
                width: 274px;
                height: 246px;
                z-index: -1;
            }

            & .circle:nth-child(2) {
                left: 50%;
                transform: translateX(-50%);
            }

            & .circle:nth-child(2) .circle-img {
                position: absolute;
                top: -120px;
                right: -40px;
                width: 274px;
                height: 246px;
                z-index: -1;
            }

            & .circle:nth-child(3) {
                right: 60px;
            }

            & .circle:nth-child(3) .circle-img {
                position: absolute;
                bottom: -100px;
                right: -50px;
                width: 274px;
                height: 246px;
                z-index: -1;
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .carrer-desired-wrapper {
        width: 100%;
        height: 100vh;
        & .carrer-desired-box {
            width: 100%;
            height: 100%;

            & > h2 {
                margin: 0;
                padding-left: 60px;
                box-sizing: border-box;
            }

            & .carrer-desired-circle {
                display: flex;
                position: relative;
                margin-top: 100px;

                & .circle {
                    border: 2px solid var(--color-white);
                    border-radius: 50%;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32vw;
                    height: 32vw;
                    max-width: 640px;
                    max-height: 640px;
                    perspective: 1000px;

                    & .content {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        backface-visibility: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: transform 0.6s;
                        color: var(--color-white);
                    }

                    & .content.front {
                        box-sizing: border-box;
                        border-radius: 50%;
                        & > p {
                            display: flex;
                            flex-direction: column;
                            gap: 40px;
                            text-align: center;

                            & > span:nth-of-type(1) {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 34px;
                                letter-spacing: -0.18px;
                            }

                            & > span:nth-of-type(2) {
                                font-size: 44px;
                                font-weight: 700;
                                line-height: 48px;
                                letter-spacing: -1.32px;
                            }
                        }
                    }

                    & .content.back {
                        box-sizing: border-box;
                        border-radius: 50%;

                        & > p {
                            display: flex;
                            flex-direction: column;
                            gap: 40px;
                            text-align: center;

                            & > span:nth-of-type(1) {
                                font-size: 40px;
                                font-weight: 700;
                                line-height: 60px;
                                letter-spacing: -1.2px;
                            }

                            & > span:nth-of-type(2) {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 34px;
                                letter-spacing: -0.18px;
                                width: 320px;
                            }
                        }
                    }
                }

                & .circle-img {
                    position: absolute;
                    pointer-events: none;
                }

                & .circle .back {
                    transform: rotateY(180deg);
                }

                & .circle:hover .front {
                    transform: rotateY(180deg);
                }

                & .circle:hover .back {
                    transform: rotateY(360deg);
                    background-color: var(--color-white);
                    color: var(--color-black);
                    border-radius: 50%;
                }

                & .circle:nth-child(1) {
                    left: 60px;
                }

                & .circle:nth-child(1) .circle-img {
                    position: absolute;
                    bottom: -20px;
                    left: -20px;
                    width: 150.625px;
                    height: 148.75px;
                    z-index: -1;
                }

                & .circle:nth-child(2) {
                    left: 50%;
                    transform: translateX(-50%);
                }

                & .circle:nth-child(2) .circle-img {
                    position: absolute;
                    top: -60px;
                    right: 0px;
                    width: 171.25px;
                    height: 153.75px;
                    z-index: -1;
                }

                & .circle:nth-child(3) {
                    right: 60px;
                }

                & .circle:nth-child(3) .circle-img {
                    position: absolute;
                    bottom: -10px;
                    right: 0px;
                    width: 143.697px;
                    height: 113.042px;
                    z-index: -1;
                }
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .carrer-desired-wrapper {
        width: 100%;
        height: 100%;
        & .carrer-desired-box {
            width: 100%;
            height: 100%;

            & > h2 {
                margin: 0;
                padding-left: 60px;
                box-sizing: border-box;
            }

            & .carrer-desired-circle {
                display: flex;
                height: 100%;
                position: relative;
                flex-direction: column; /* 원을 세로로 정렬합니다. */
                align-items: center; /* 원을 가운데 정렬합니다. */
                padding-top: 0px;
                padding: 0 20px;
                box-sizing: border-box;

                & .circle {
                    border: 2px solid var(--color-white);
                    border-radius: 50%;
                    position: static; /* 절대 위치 지정을 제거하고, 자연스러운 흐름을 따르도록 합니다. */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 696px;
                    height: 696px;
                    perspective: 1000px;
                    margin-top: -50px; /* 원들 사이의 겹침 효과 조정 */

                    &:first-child {
                        margin-top: 0;
                    }

                    & .content {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        backface-visibility: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: transform 0.6s;
                        color: var(--color-white);
                    }

                    & .content.front {
                        box-sizing: border-box;
                        border-radius: 50%;
                        & > p {
                            display: flex;
                            flex-direction: column;
                            gap: 40px;
                            text-align: center;

                            & > span:nth-of-type(1) {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 34px;
                                letter-spacing: -0.18px;
                            }

                            & > span:nth-of-type(2) {
                                font-size: 44px;
                                font-weight: 700;
                                line-height: 48px;
                                letter-spacing: -1.32px;
                            }
                        }
                    }

                    & .content.back {
                        box-sizing: border-box;
                        border-radius: 50%;

                        & > p {
                            display: flex;
                            flex-direction: column;
                            gap: 40px;
                            text-align: center;

                            & > span:nth-of-type(1) {
                                font-size: 40px;
                                font-weight: 700;
                                line-height: 60px;
                                letter-spacing: -1.2px;
                            }

                            & > span:nth-of-type(2) {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 34px;
                                letter-spacing: -0.18px;
                                width: 320px;
                            }
                        }
                    }
                }

                & .circle .back {
                    transform: rotateY(180deg);
                }

                & .circle:hover .front {
                    transform: rotateY(180deg);
                }

                & .circle:hover .back {
                    transform: rotateY(360deg);
                    background-color: var(--color-white);
                    color: var(--color-black);
                    border-radius: 50%;
                }

                & .circle:nth-child(1) {
                    left: 0;
                }

                & .circle:nth-child(1) .circle-img {
                    position: absolute;
                    bottom: 0;
                    top: -20px;
                    left: unset;
                    right: 0px;
                    width: 150.625px;
                    height: 148.75px;
                    z-index: -1;
                }

                & .circle:nth-child(2) {
                    left: 0;
                    transform: translateX(0);
                }

                & .circle:nth-child(2) .circle-img {
                    position: absolute;
                    top: 40px;
                    right: unset;
                    left: -40px;
                    width: 171.25px;
                    height: 153.75px;
                    z-index: -1;
                }

                & .circle:nth-child(3) {
                    right: 0;
                }

                & .circle:nth-child(3) .circle-img {
                    position: absolute;
                    bottom: -20px;
                    right: 30px;
                    width: 143.697px;
                    height: 113.042px;
                    z-index: -1;
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .carrer-desired-wrapper {
        width: 100%;
        height: 100%;
        margin-top: 80px;

        & .carrer-desired-box {
            width: 100%;
            height: 100%;

            & > h2 {
                margin: 0;
                padding-left: 20px;
                box-sizing: border-box;
            }

            & .carrer-desired-circle {
                display: flex;
                height: 100%;
                position: relative;
                flex-direction: column;
                align-items: center;
                padding-top: 0px;
                padding: 0 20px;
                box-sizing: border-box;

                & .circle {
                    border: 2px solid var(--color-white);
                    border-radius: 50%;
                    position: static;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 90vw;
                    max-height: 90vw;
                    perspective: 1000px;
                    margin-top: -50px;

                    &:first-child {
                        margin-top: 0;
                    }

                    & .content {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        backface-visibility: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: transform 0.6s;
                        color: var(--color-white);
                    }

                    & .cotent.mobile {
                        color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        gap: 16px;

                        & > p:nth-of-type(1) {
                            display: flex;
                            flex-direction: column;
                            & > span:nth-of-type(1) {
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 20px;
                                letter-spacing: -0.14px;
                            }
                            & > span:nth-of-type(2) {
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 26px;
                                letter-spacing: -0.2px;
                            }
                        }

                        & > p:nth-of-type(2) {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22px;
                            padding: 0 40px;
                            box-sizing: border-box;
                        }
                    }
                }

                & .circle:nth-child(1) {
                    left: 0;
                }

                & .circle:nth-child(1) .circle-img {
                    position: absolute;
                    bottom: 0;
                    top: -20px;
                    left: unset;
                    right: 0px;
                    width: 150.625px;
                    height: 148.75px;
                    z-index: -1;
                }

                & .circle:nth-child(2) {
                    left: 0;
                    transform: translateX(0);
                }

                & .circle:nth-child(2) .circle-img {
                    position: absolute;
                    top: 40px;
                    right: unset;
                    left: -40px;
                    width: 171.25px;
                    height: 153.75px;
                    z-index: -1;
                }

                & .circle:nth-child(3) {
                    right: 0;
                }

                & .circle:nth-child(3) .circle-img {
                    position: absolute;
                    bottom: -20px;
                    right: 30px;
                    width: 143.697px;
                    height: 113.042px;
                    z-index: -1;
                }
            }
        }
    }
}
@media screen and (max-width: 390px) {
    .carrer-desired-wrapper {
        width: 100%;
        height: 100%;
        margin-top: 80px;

        & .carrer-desired-box {
            width: 100%;
            height: 100%;

            & > h2 {
                margin: 0;
                padding-left: 20px;
                box-sizing: border-box;
            }

            & .carrer-desired-circle {
                display: flex;
                height: 100%;
                position: relative;
                flex-direction: column;
                align-items: center;
                padding-top: 0px;
                padding: 0 20px;
                box-sizing: border-box;

                & .circle {
                    border: 2px solid var(--color-white);
                    border-radius: 50%;
                    position: static;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 90vw;
                    max-height: 90vw;
                    perspective: 1000px;
                    margin-top: -50px;

                    &:first-child {
                        margin-top: 0;
                    }

                    & .content {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        backface-visibility: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: transform 0.6s;
                        color: var(--color-white);
                    }

                    & .cotent.mobile {
                        color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        gap: 16px;

                        & > p:nth-of-type(1) {
                            display: flex;
                            flex-direction: column;
                            & > span:nth-of-type(1) {
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 20px;
                                letter-spacing: -0.14px;
                            }
                            & > span:nth-of-type(2) {
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 26px;
                                letter-spacing: -0.2px;
                            }
                        }

                        & > p:nth-of-type(2) {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22px;
                            padding: 0 40px;
                            box-sizing: border-box;
                        }
                    }
                }

                & .circle:nth-child(1) {
                    left: 0;
                }

                & .circle:nth-child(1) .circle-img {
                    position: absolute;
                    bottom: 0;
                    top: -20px;
                    left: unset;
                    right: 0px;
                    width: 102.75px;
                    height: 92.25px;
                    z-index: -1;
                }

                & .circle:nth-child(2) {
                    left: 0;
                    transform: translateX(0);
                }

                & .circle:nth-child(2) .circle-img {
                    position: absolute;
                    top: 40px;
                    right: unset;
                    left: -10px;
                    width: 90.375px;
                    height: 89.25px;
                    z-index: -1;
                }

                & .circle:nth-child(3) {
                    right: 0;
                }

                & .circle:nth-child(3) .circle-img {
                    position: absolute;
                    bottom: -20px;
                    right: 30px;
                    width: 86.218px;
                    height: 67.825px;
                    z-index: -1;
                }
            }
        }
    }
}

.welfare-wrapper {
    padding-top: 200px;

    & .welfare-box {
        padding: 0 60px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 100px;
        margin: 0 auto;

        & > h2 {
            margin: 0;
        }

        & .welfare-card-box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 36px;

            & .welfare-card {
                display: flex;
                flex-direction: column;
                gap: 40px;
                width: 300px;

                & .welfare-card-icon {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & > img {
                        width: 75%;
                        height: auto;
                    }
                }

                & .welfare-card-content {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    color: var(--color-white);
                    text-align: center;

                    & > div {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                    }

                    & > p {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: -0.16px;
                    }

                    & > p:first-child {
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 34px;
                        letter-spacing: -0.24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .welfare-wrapper {
        padding-top: 100px;

        & .welfare-box {
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin: 0 auto;

            & > h2 {
                margin: 0;
            }

            & .welfare-card-box {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 36px;

                & .welfare-card {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    & .welfare-card-icon {
                        width: 80px;
                        height: 80px;
                    }

                    & .welfare-card-content {
                        display: flex;
                        flex-direction: column;
                        color: var(--color-white);
                        text-align: start;

                        & > div {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                        }

                        & > p {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                        }

                        & > p:first-child {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 34px;
                            letter-spacing: -0.24px;
                        }
                    }
                }
            }
        }
    }
}

.recruit-wrapper {
    padding: 200px 60px;
    box-sizing: border-box;

    & .recruit-box {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & .recruit-title {
            margin: 0;
        }

        & .recruit-content {
            width: 1000px;

            & .recruit-card {
                width: 100%;
                padding: 40px 0;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                gap: 40px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);

                & .card-status {
                    background-color: var(--color-white);
                    border-radius: 20px;
                    padding: 0px 20px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: -0.16px;
                    cursor: pointer;
                }

                & .card-status.end {
                    background-color: #444;
                    color: #999;
                }

                & .card-status.pending {
                    border: 2px solid var(--color-white);
                    background-color: var(--color-black);
                    color: var(--color-white);
                }

                & .card-text-box {
                    color: var(--color-white);
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    cursor: pointer;

                    & .main-text {
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 34px;
                        letter-spacing: -0.24px;
                    }

                    & .sub-text {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 26px;
                        letter-spacing: -0.16px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .recruit-wrapper {
        padding: 200px 60px;
        box-sizing: border-box;

        & .recruit-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: unset;
            gap: 40px;

            & .recruit-title {
                margin: 0;
            }

            & .recruit-content {
                width: 100%;

                & .recruit-card {
                    width: 100%;
                    padding: 40px 0;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

                    & .card-status {
                        background-color: var(--color-white);
                        border-radius: 20px;
                        padding: 0px 20px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        height: 40px;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: -0.16px;
                    }

                    & .card-status.end {
                        background-color: #444;
                        color: #999;
                    }

                    & .card-text-box {
                        color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        & .main-text {
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 34px;
                            letter-spacing: -0.24px;
                        }

                        & .sub-text {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .recruit-wrapper {
        padding: 100px 20px;
        box-sizing: border-box;

        & .recruit-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: unset;
            gap: 40px;

            & .recruit-title {
                margin: 0;
            }

            & .recruit-content {
                width: 100%;

                & .recruit-card {
                    width: 100%;
                    padding: 40px 0;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

                    & .card-status {
                        background-color: var(--color-white);
                        border-radius: 20px;
                        padding: 0px 20px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        height: 28px;
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 16px;
                        letter-spacing: -0.13px;
                    }

                    & .card-status.end {
                        background-color: #444;
                        color: #999;
                    }

                    & .card-text-box {
                        color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        & .main-text {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                        }

                        & .sub-text {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: -0.14px;
                        }
                    }
                }
            }
        }
    }
}
