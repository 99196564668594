.main-screen-wrapper {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 745px) {
    .main-screen-wrapper {
        flex-direction: column;
        position: relative;

        & .main-screen-arrow {
            color: var(--color-white);
            text-align: center;
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.13px;

            position: absolute;
            cursor: pointer;
            bottom: 30px;
        }
    }
}

.main-screen-overlay {
    width: 745px;
    height: 745px;
    flex-shrink: 0;
    position: relative;

    & .main-screen-img {
        width: 100%;
        height: 100%;
    }

    & .main-screen-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        color: var(--color-white);

        & > h1 {
            align-self: stretch;
            color: #fff;
            font-size: 140px;
            font-weight: 700;
            line-height: 140px;
            letter-spacing: -2px;
            margin: 0;
        }

        & > h1:not(:nth-of-type(3)) {
            font-family: 'Playfair Display';
            font-style: italic;
        }

        & > h1:nth-of-type(2) {
            text-align: right;
        }

        & > h1:nth-of-type(3) {
            text-align: center;
        }
    }
}

@media screen and (max-width: 745px) {
    .main-screen-overlay {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        & .main-screen-img {
            width: 100%;
            height: auto;
        }

        & .main-screen-text {
            & > h1 {
                font-size: 100px;
                line-height: 100px;
                letter-spacing: -2px;
            }
        }
    }
}
@media screen and (max-width: 375px) {
    .main-screen-overlay {
        & .main-screen-text {
            & > h1 {
                font-size: 64px;
                line-height: 60px;
                letter-spacing: -1.92px;
            }
        }
    }
}

.second-wrapper {
    width: 100%;
    height: 100vh;

    position: relative;
    overflow: hidden;

    & .second-text-box {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        color: var(--color-white);

        & > p {
            margin: 0;
            font-size: 160px;
            font-weight: 600;
            line-height: 180px;
            letter-spacing: -5.4px;
            z-index: 1;
        }

        & > p:nth-of-type(2),
        & > p:nth-of-type(4) {
            font-family: 'Playfair Display';
            font-style: italic;
        }
    }

    & .second-img {
        position: absolute;

        & > img {
            width: 100%;
            height: 100%;
        }
    }

    & .second-img:nth-of-type(1) {
        top: 70px;
        left: 300px;
        width: 200px;
        height: 170px;
    }

    & .second-img:nth-of-type(2) {
        bottom: 50px;
        right: 220px;
        width: 200px;
        height: 170px;
    }

    & .second-img:nth-of-type(3) {
        bottom: 70px;
        left: 0px;
        width: 240px;
        height: 250px;
    }

    & .second-img:nth-of-type(4) {
        top: 150px;
        right: -70px;
        width: 240px;
        height: 220px;
    }
}

@media screen and (max-width: 1400px) {
    .second-wrapper {
        & .second-text-box {
            & > p {
                margin: 0;
                font-size: 155px;
                font-weight: 600;
                line-height: 180px;
                letter-spacing: -5.4px;
                z-index: 1;
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .second-wrapper {
        height: 100%;
        padding: 81px 40px;

        & .second-text-box {
            & > p {
                font-size: 120px;
                line-height: 120px;
                letter-spacing: -3px;
            }
        }

        & .second-img {
            position: absolute;

            & > img {
                width: 100%;
                height: auto;
            }
        }

        & .second-img:nth-of-type(1) {
            top: 40px;
            left: 180px;
            width: 190px;
            height: 190px;
        }

        & .second-img:nth-of-type(2) {
            bottom: 45px;
            right: 150px;
            width: 137.455px;
            height: 135px;
        }

        & .second-img:nth-of-type(3) {
            bottom: 0;
            left: 0;
            width: 180px;
            height: 220px;
        }

        & .second-img:nth-of-type(4) {
            top: 120px;
            right: -50px;
            width: 200px;
            height: 200px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .second-wrapper {
        & .second-text-box {
            & > p {
                font-size: 100px;
                line-height: 100px;
                letter-spacing: -2px;
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .second-wrapper {
        height: 1133px;
        padding: 0;

        display: flex;
        justify-content: end;
        align-items: center;

        & .second-text-box {
            width: 410px;
            height: 700px;
            text-align: left;
            margin-right: 40px;

            & > p {
                font-size: 100px;
                line-height: 100px;
                letter-spacing: -3px;
            }
        }

        & .second-img {
            position: absolute;

            & > img {
                width: 100%;
                height: auto;
            }
        }

        & .second-img:nth-of-type(1) {
            top: 100px;
            left: 100px;
            width: 170.375px;
            height: 150.125px;
            flex-shrink: 0;
        }

        & .second-img:nth-of-type(2) {
            bottom: 70px;
            right: 100px;
            width: 122.182px;
            height: 120px;
            flex-shrink: 0;
        }

        & .second-img:nth-of-type(3) {
            bottom: 180px;
            left: 0;
            width: 240px;
            height: 266.4px;
            flex-shrink: 0;
        }

        & .second-img:nth-of-type(4) {
            top: 400px;
            right: -50px;
            width: 190px;
            height: 190px;
            flex-shrink: 0;
        }
    }
}
@media screen and (max-width: 475px) {
    .second-wrapper {
        height: 812px;

        & .second-text-box {
            width: 262px;
            height: 432px;

            margin-right: 40px;

            text-align: left;

            & > p {
                font-size: 64px;
                font-weight: 600;
                line-height: 64px;
                letter-spacing: -2px;
            }
        }

        & .second-img {
            width: 100%;
            & > img {
                width: 100%;
                height: auto;
            }
        }

        & .second-img:nth-of-type(1) {
            top: 120px;
            left: 70px;
            width: 102.75px;
            height: 92.25px;
        }

        & .second-img:nth-of-type(2) {
            bottom: 50px;
            right: 20px;
        }

        & .second-img:nth-of-type(3) {
            bottom: 220px;
            left: -40px;
            width: 150px;
            height: 142.6px;
        }

        & .second-img:nth-of-type(4) {
            top: 300px;
            right: -30px;
            width: 120.412px;
            height: 120.412px;
            flex-shrink: 0;
        }
    }
}
@media screen and (max-width: 380px) {
    .second-wrapper {
        height: 812px;

        & .second-text-box {
            width: 262px;
            height: 432px;
            flex-shrink: 0;

            & > p {
                font-size: 64px;
                font-weight: 600;
                line-height: 64px;
                letter-spacing: -2px;
            }
        }

        & .second-img {
            width: 30%;
            & > img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.portfolio-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding: 160px 0px 220px 0px;

    & .portfolio-text {
        width: 100%;
        text-align: center;

        & > h2 {
            font-size: 100px;
            font-weight: 600;
            line-height: 100px;
            letter-spacing: -3px;
            color: var(--color-white);

            & > span {
                font-family: 'Playfair Display';
                font-style: italic;
                font-weight: 500;
                line-height: 100px;
                letter-spacing: -3px;
            }
        }
    }

    & .portfolio-img {
        width: 100%;
        max-width: 1280px;
        margin: 40px auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;

        & .portfolio-img-box {
            width: 100%;
            height: 406px;
            border-radius: 20px;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            & .overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.6) 5%,
                    rgba(0, 0, 0, 0) 100%
                );
            }

            & > img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            & .portfolio-img-overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 25px;
                display: flex;
                flex-direction: column;
                justify-content: end;

                & .portfolio-img-text {
                    width: 100%;
                    height: 100%;
                    color: var(--color-white);
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 34px;
                    letter-spacing: -0.24px;

                    & > p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .portfolio-wrapper {
        & .portfolio-img {
            width: 100%;
            max-width: 1280px;
            margin: 40px auto;
            padding: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 20px;

            & .portfolio-img-box {
                height: 400px;

                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
@media screen and (max-width: 1080px) {
    .portfolio-wrapper {
        & .portfolio-text {
            & > h2 {
                font-size: 80px;
                line-height: 80px;
                letter-spacing: -2.4px;
            }
        }

        & .portfolio-img {
            width: 100%;
            max-width: 745px;
            margin: 40px auto;
            gap: 20px;

            & .portfolio-img-box {
                width: 100%;
                height: 100%;
                border-radius: 20px;
                position: relative;
                overflow: hidden;

                & > img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }

                & .portfolio-img-overlay {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 744px) {
    .portfolio-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        padding: 40px 0px 80px 0px;

        & .portfolio-text {
            width: 100%;
            text-align: center;

            & > h2 {
                font-size: 80px;
                line-height: 80px;
                letter-spacing: -2.4px;
            }
        }

        & .portfolio-img {
            width: 100%;
            max-width: 1280px;
            margin: 40px auto;
            padding: 0 10px;
            gap: 8px;
            display: flex;
            flex-direction: column;

            & .portfolio-img-box {
                width: 100%;
                height: 406px;
                border-radius: 20px;
                position: relative;
                overflow: hidden;

                & > img {
                    width: 100%;
                    height: 100%;
                }

                & .portfolio-img-overlay {
                    padding: 16px;

                    & .portfolio-img-text {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: -0.16px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 475px) {
    .portfolio-wrapper {
        & .portfolio-text {
            width: 100%;
            text-align: center;

            & > h2 {
                font-size: 44px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: -1.32px;
            }
        }

        & .portfolio-img {
            width: 100%;
            max-width: 100%;
            margin: 40px auto;
            padding: 0 10px;
            gap: 8px;
            display: flex;
            flex-direction: column;

            & .portfolio-img-box {
                width: 100%;
                height: 236px;
                border-radius: 20px;
                position: relative;
                overflow: hidden;
            }
        }
    }
}
