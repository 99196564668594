.about-title-wrapper {
    width: 100%;
    height: 1137px;

    & .about-title-sub-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 46px;

        transition: all 0.3s ease;

        & .about-title {
            width: 1200px;
            height: 256px;
            flex-shrink: 0;
            border-radius: 128px;
            border: 2px solid #fff;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(20px);
            display: flex;
            justify-content: center;
            align-items: center;

            transition: all 0.3s ease;

            & > h1 {
                margin: 0;
                padding: 0;
                color: var(--color-white);
                text-align: center;
                font-family: 'Playfair Display';
                font-size: 180px;
                font-style: italic;
                font-weight: 500;
                line-height: 180px;
                letter-spacing: -2px;
                margin-bottom: 20px;
                transition: all 0.3s ease;
            }
        }

        & .about-sub-title {
            & > p {
                color: var(--color-white);
                margin: 0;
                text-align: center;
                font-size: 44px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: -1.32px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .about-title-wrapper {
        width: 100%;
        height: 100vh;

        & .about-title-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 46px;

            transition: all 0.3s ease;

            & .about-title {
                max-width: 850px;
                width: 100%;
                height: 208px;
                flex-shrink: 0;
                border-radius: 128px;
                border: 2px solid #fff;
                background: rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(20px);
                display: flex;
                justify-content: center;
                align-items: center;

                transition: all 0.3s ease;

                & > h1 {
                    margin: 0;
                    padding: 0;
                    color: var(--color-white);
                    text-align: center;
                    font-family: 'Playfair Display';
                    font-size: 140px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: 180px;
                    letter-spacing: -2px;
                    margin-bottom: 20px;
                    transition: all 0.3s ease;
                }
            }

            & .about-sub-title {
                & > p {
                    color: var(--color-white);
                    margin: 0;
                    text-align: center;
                    font-size: 44px;
                    font-weight: 700;
                    line-height: 48px;
                    letter-spacing: -1.32px;
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .about-title-wrapper {
        width: 100%;
        height: 100vh;

        & .about-title-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 46px;
            padding: 0 20px;
            box-sizing: border-box;

            transition: all 0.3s ease;

            & .about-title {
                max-width: 704px;
                width: 100%;
                height: 182px;
                flex-shrink: 0;
                border-radius: 128px;
                border: 2px solid #fff;
                background: rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(20px);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s ease;

                & > h1 {
                    margin: 0;
                    padding: 0;
                    color: var(--color-white);
                    text-align: center;
                    font-family: 'Playfair Display';
                    font-size: 120px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: 180px;
                    letter-spacing: -2px;
                    margin-bottom: 20px;
                    transition: all 0.3s ease;
                }
            }

            & .about-sub-title {
                & > p {
                    color: var(--color-white);
                    margin: 0;
                    text-align: center;
                    font-size: 44px;
                    font-weight: 700;
                    line-height: 48px;
                    letter-spacing: -1.32px;
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .about-title-wrapper {
        width: 100%;
        height: 100vh;

        & .about-title-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;

            transition: all 0.3s ease;

            & .about-title {
                max-width: 350px;
                width: 100%;
                height: 90px;
                flex-shrink: 0;
                border-radius: 128px;
                border: 2px solid #fff;
                background: rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(20px);
                display: flex;
                justify-content: center;
                align-items: center;

                transition: all 0.3s ease;

                & > h1 {
                    margin: 0;
                    padding: 0;
                    color: var(--color-white);
                    text-align: center;
                    font-family: 'Playfair Display';
                    font-size: 55px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: 60px;
                    letter-spacing: -2px;
                    margin-bottom: 16px;
                    transition: all 0.3s ease;
                }
            }

            & .about-sub-title {
                & > p {
                    color: var(--color-white);
                    margin: 0;
                    text-align: center;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 48px;
                    letter-spacing: -1.32px;
                }
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .about-title-wrapper {
        width: 100%;
        height: 100vh;

        & .about-title-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;

            transition: all 0.3s ease;

            & .about-title {
                width: 280px;
                height: 72px;
                flex-shrink: 0;
                border-radius: 128px;
                border: 2px solid #fff;
                background: rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(20px);
                display: flex;
                justify-content: center;
                align-items: center;

                & > h1 {
                    margin: 0;
                    padding: 0;
                    color: var(--color-white);
                    text-align: center;
                    font-family: 'Playfair Display';
                    font-size: 45px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: 48px;
                    letter-spacing: -1px;
                    margin-bottom: 10px;
                    transition: all 0.3s ease;
                }
            }

            & .about-sub-title {
                & > p {
                    color: var(--color-white);
                    margin: 0;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 48px;
                    letter-spacing: -1.32px;
                }
            }
        }
    }
}

.fade-card-warapper {
    width: 100%;
    height: 100%;

    padding: 0 20px;

    & .fade-card-text-box {
        & > h3 {
            margin: 0;
            color: var(--color-white);
            text-align: center;
            font-size: 80px;
            font-weight: 600;
            line-height: 80px;
            letter-spacing: -2.4px;

            & > span {
                font-family: 'Playfair Display';
                font-style: italic;
            }
        }
    }

    & .fade-card-box {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        margin-top: 80px;

        & .fade-card-sub-box {
            width: 100%;
            height: 100%;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            & .fade-card {
                position: relative;
                border-radius: 20px;
                max-width: 930px;
                max-height: 935px;

                transition: all 0.5s ease;

                & .fade-card-text {
                    width: 100%;
                    height: 100%;

                    position: absolute;
                    padding: 60px 40px;
                    top: 0;
                    left: 0;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    color: var(--color-white);

                    & .fade-card-text-top {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        & > h5 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                        }

                        & > h4 {
                            margin: 0;
                            font-size: 64px;
                            font-weight: 600;
                            line-height: 60px;
                            letter-spacing: -0.064px;
                        }
                    }

                    & > p {
                        opacity: 0;
                        visibility: hidden;
                        transition:
                            opacity 0.3s ease,
                            visibility 0.3s ease;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: -0.8px;
                        padding-right: 190px;
                    }
                }

                &:hover {
                    & .fade-card-img {
                        filter: brightness(0.5);
                        transition: all 0.5s ease;
                    }

                    & .fade-card-text {
                        & .fade-card-text-top {
                            & > h4 {
                                font-family: 'Playfair Display';
                                font-style: italic;
                            }
                        }

                        & > p {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .fade-card-warapper {
        width: 100%;
        height: 100%;

        padding: 0 20px;

        & .fade-card-text-box {
            & > h3 {
                margin: 0;
                color: var(--color-white);
                text-align: center;
                font-size: 80px;
                font-weight: 600;
                line-height: 80px;
                letter-spacing: -2.4px;

                & > span {
                    font-family: 'Playfair Display';
                    font-style: italic;
                }
            }
        }

        & .fade-card-box {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            margin-top: 80px;

            & .fade-card-sub-box {
                width: 100%;
                height: 100%;

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                & .fade-card {
                    position: relative;
                    border-radius: 20px;
                    max-width: 930px;
                    max-height: 935px;

                    transition: all 0.5s ease; /* 이 위치가 올바름 */

                    & .fade-card-text {
                        width: 100%;
                        height: 100%;

                        position: absolute;
                        padding: 60px 40px;
                        top: 0;
                        left: 0;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        color: var(--color-white);

                        & .fade-card-text-top {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            & > h5 {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 26px;
                                letter-spacing: -0.16px;
                            }

                            & > h4 {
                                margin: 0;
                                font-size: 64px;
                                font-weight: 600;
                                line-height: 60px;
                                letter-spacing: -0.064px;
                            }
                        }

                        & > p {
                            opacity: 0;
                            visibility: hidden;
                            transition:
                                opacity 0.3s ease,
                                visibility 0.3s ease;
                            margin: 0;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 26px;
                            letter-spacing: -0.16px;
                            padding-right: 0px;
                        }
                    }

                    &:hover {
                        & .fade-card-img {
                            filter: brightness(0.5);
                        }

                        & .fade-card-text {
                            & .fade-card-text-top {
                                & > h4 {
                                    font-family: 'Playfair Display';
                                    font-style: italic;
                                }
                            }

                            & > p {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .fade-card-warapper {
        width: 100%;
        height: 100%;

        padding: 0 20px;

        & .fade-card-text-box {
            & > h3 {
                margin: 0;
                color: var(--color-white);
                text-align: center;
                font-size: 80px;
                font-weight: 600;
                line-height: 80px;
                letter-spacing: -2.4px;

                & > span {
                    font-family: 'Playfair Display';
                    font-style: italic;
                }
            }
        }

        & .fade-card-box {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            margin-top: 80px;

            & .fade-card-sub-box {
                width: 100%;
                height: 100%;

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                & .fade-card {
                    position: relative;
                    border-radius: 20px;
                    max-width: 930px;
                    max-height: 935px;

                    transition: all 0.5s ease; /* 이 위치가 올바름 */

                    & .fade-card-text {
                        width: 100%;
                        height: 100%;

                        position: absolute;
                        padding: 60px 40px;
                        top: 0;
                        left: 0;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        color: var(--color-white);

                        & .fade-card-text-top {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            & > h5 {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 26px;
                                letter-spacing: -0.16px;
                            }

                            & > h4 {
                                margin: 0;
                                font-size: 32px;
                                font-weight: 600;
                                line-height: 38px;
                                letter-spacing: -0.96px;
                            }
                        }

                        & > p {
                            opacity: 0;
                            visibility: hidden;
                            transition:
                                opacity 0.3s ease,
                                visibility 0.3s ease;
                            margin: 0;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22px;
                            padding-right: 0px;
                        }
                    }

                    &:hover {
                        & .fade-card-img {
                            filter: brightness(0.5);
                        }

                        & .fade-card-text {
                            & .fade-card-text-top {
                                & > h4 {
                                    font-family: 'Playfair Display';
                                    font-style: italic;
                                }
                            }

                            & > p {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .fade-card-warapper {
        width: 100%;
        height: 100%;
        padding: 0;
        box-sizing: border-box;

        & .fade-card-text-box {
            padding: 0 20px;
            box-sizing: border-box;
            & > h3 {
                margin: 0;
                color: var(--color-white);
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                line-height: 34px;
                letter-spacing: -0.24px;

                & > span {
                    font-family: 'Playfair Display';
                    font-style: italic;
                }
            }
        }

        & .fade-card-box {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            margin-top: 32px;

            & .swiper-container {
                width: 100%;
                height: 100%;
                display: flex;
                position: relative;
                padding-bottom: 50px;
                overflow: hidden;

                & .swiper-wrapper {
                    width: 100%;
                    height: 100%;

                    & .swiper-slide {
                        width: 90% !important;
                    }

                    & .fade-card {
                        border-radius: 20px;
                        width: 584px;
                        height: 700px;

                        & .fade-card-img {
                            width: 100%;
                            height: 100%;

                            & > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 20px;
                            }
                        }

                        & .fade-card-text {
                            width: 100%;
                            height: 100%;

                            position: absolute;
                            padding: 20px 16px;
                            top: 0;
                            left: 0;

                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            color: var(--color-white);

                            & .fade-card-text-top {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                & > h5 {
                                    margin: 0;
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 26px;
                                    letter-spacing: -0.16px;
                                }

                                & > h4 {
                                    margin: 0;
                                    font-size: 32px;
                                    font-weight: 600;
                                    line-height: 38px;
                                    letter-spacing: -0.96px;
                                    font-family: 'Playfair Display';
                                    font-style: italic;
                                }
                            }

                            & > p {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                                padding-right: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 390px) {
    .fade-card-warapper {
        & .fade-card-box {
            & .swiper-container {
                & .swiper-wrapper {
                    & .fade-card {
                        width: 584px;
                        height: 584px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .fade-card-warapper {
        & .fade-card-box {
            & .swiper-container {
                & .swiper-wrapper {
                    & .swiper-slide {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.business-wrapper {
    width: 100%;
    height: 100%;

    padding: 200px 0;
    overflow: hidden;

    & .pin-spacer {
        background-color: var(--color-background-bus);
    }

    & .business-sub-wrapper {
        min-width: 100vw;
        height: 100vh;

        display: flex;
        flex-direction: row;
        align-items: center;

        & .business-title-box {
            min-width: max-content;

            padding-left: 76px;
            padding-right: 234px;

            & > img {
                width: 100%;
                height: 100%;
            }
        }

        & .business-card-box {
            display: flex;
            gap: 80px;

            & .business-card {
                position: relative;
                min-width: 480px;

                & .business-card-content {
                    width: 100%;
                    height: 100%;
                    padding: 20px 40px;
                    position: absolute;
                    top: 0;
                    left: 0;

                    color: var(--color-white);

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    & > h3 {
                        margin: 0;
                        font-size: 44px;
                        font-weight: 700;
                        line-height: 48px;
                        letter-spacing: -1.32px;
                    }

                    & > p {
                        margin: 0;
                        width: 100%;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 26px;
                        letter-spacing: -0.18px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .business-wrapper {
        width: 100%;
        height: 100%;

        padding: 200px 0;
        overflow: hidden;

        & .pin-spacer {
            background-color: var(--color-background-bus);
        }

        & .business-sub-wrapper {
            min-width: 100vw;
            height: 100vh;

            display: flex;
            flex-direction: row;
            align-items: center;

            & .business-title-box {
                min-width: max-content;

                padding-left: 76px;
                padding-right: 234px;

                & > img {
                    width: 100%;
                    height: 100%;
                }
            }

            & .business-card-box {
                display: flex;
                gap: 80px;

                & .business-card {
                    position: relative;
                    min-width: 480px;

                    & .business-card-content {
                        width: 100%;
                        height: 100%;
                        padding: 20px 40px;
                        position: absolute;
                        top: 0;
                        left: 0;

                        color: var(--color-white);

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        & > h3 {
                            margin: 0;
                            font-size: 44px;
                            font-weight: 700;
                            line-height: 48px;
                            letter-spacing: -1.32px;
                        }

                        & > p {
                            margin: 0;
                            width: 173px;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 26px;
                            letter-spacing: -0.18px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .business-wrapper {
        width: 100%;
        height: 100%;

        padding: 50px 0;
        overflow: hidden;

        & .business-sub-wrapper {
            min-width: 100%;
            height: 100%;
            background-color: var(--color-background-bus);
            padding: 0 20px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & .business-title-box {
                min-width: max-content;

                padding-left: 0px;
                padding-top: 40px;
                padding-right: 0px;

                & > img {
                    width: 100%;
                    height: 100%;
                }

                & > p {
                    color: var(--color-white);
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: -0.96px;
                }
            }

            & .business-card-box {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 80px;
                padding: 70px 0px;
                box-sizing: border-box;
                justify-content: unset;
                align-items: flex-end;

                & .business-card {
                    width: 100%;
                    position: relative;
                    min-width: 85%;

                    & .business-card-img {
                        width: 100%;
                        height: 100%;

                        & > img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 20px;
                        }
                    }

                    &:nth-child(odd) {
                        & .business-card-img {
                            width: 85%;
                            height: 100%;

                            & > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 20px;
                            }
                        }
                    }

                    & .business-card-content {
                        width: 100%;
                        height: 100%;
                        padding: 20px 16px;
                        box-sizing: border-box;
                        position: absolute;
                        top: 0;
                        left: 0;

                        color: var(--color-white);

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        & > h3 {
                            margin: 0;
                            font-size: 28px;
                            font-weight: 600;
                            line-height: 40px;
                            letter-spacing: -0.28px;
                        }

                        & > p {
                            margin: 0;
                            width: 173px;
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 25px;
                            letter-spacing: -0.13px;
                        }
                    }
                }
            }
        }
    }
}

.history-wrapper {
    width: 100%;
    height: 100%;

    & .history-sub-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        padding: 0 64px;
        padding-bottom: 200px;

        & .history-title-overlay {
        }

        & .history-main-box {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: end;

            & .history-sub-box {
                max-width: 1000px;
                width: 100%;
                height: 100%;
                padding: 24px 0;

                display: flex;
                justify-content: space-between;
                gap: 20px;

                & .history-main-title {
                    width: 180px;
                    color: var(--color-white);

                    & > h2 {
                        margin: 0;
                        font-size: 44px;
                        font-weight: 700;
                        line-height: 48px;
                        letter-spacing: -1.32px;
                    }
                }

                & .history-main-content {
                    width: 100%;
                    color: var(--color-white);

                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    & > p {
                        position: relative;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        font-size: 28px;
                        font-weight: 400;
                        line-height: 40px;
                        letter-spacing: -0.28px;

                        & .tooltip {
                            position: absolute;
                            top: 0;
                            left: 0;
                            border-radius: 50px;
                            background-color: var(--color-white);
                            padding: 6px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            color: var(--color-black);
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 26px;
                            letter-spacing: -0.16px;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: -10px;
                                left: 10%;
                                transform: translateX(-10%);
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 10px 10px 0 10px;
                                border-color: var(--color-white) transparent
                                    transparent transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .history-wrapper {
        & .history-sub-wrapper {
            & .history-main-box {
                & .history-sub-box {
                    max-width: 750px;
                }
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .history-wrapper {
        width: 100%;
        height: 100%;

        & .history-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            padding: 0 60px;

            & .history-title-overlay {
                & > img {
                    max-width: 100%;
                }
            }

            & .history-main-box {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: end;

                & .history-sub-box {
                    max-width: 550px;
                    width: 100%;
                    height: 100%;
                    padding: 24px 0;

                    display: flex;
                    justify-content: space-between;
                    gap: 20px;

                    & .history-main-title {
                        width: 160px;
                        color: var(--color-white);

                        & > h2 {
                            margin: 0;
                            font-size: 32px;
                            font-weight: 700;
                            line-height: 48px;
                            letter-spacing: -1.32px;
                        }
                    }

                    & .history-main-content {
                        width: 100%;
                        color: var(--color-white);

                        display: flex;
                        flex-direction: column;
                        gap: 32px;

                        & > p {
                            position: relative;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            font-size: 18px;
                            font-weight: 400;
                            line-height: 40px;
                            letter-spacing: -0.28px;

                            & .tooltip {
                                position: absolute;
                                top: 0;
                                left: 0;
                                border-radius: 50px;
                                background-color: var(--color-white);
                                padding: 6px 16px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;

                                color: var(--color-black);
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 26px;
                                letter-spacing: -0.16px;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    bottom: -10px;
                                    left: 10%;
                                    transform: translateX(-10%);
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 10px 10px 0 10px;
                                    border-color: var(--color-white) transparent
                                        transparent transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .history-wrapper {
        width: 100%;
        height: 100%;

        & .history-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            padding: 0 20px;

            & .history-main-box {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: start;
                margin-top: 30px;

                & .history-sub-box {
                    max-width: 550px;
                    width: 100%;
                    height: 100%;
                    padding: 20px 0;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 10px;

                    & .history-main-title {
                        width: 100px;
                        color: var(--color-white);

                        & > h2 {
                            margin: 0;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 34px;
                            letter-spacing: -0.24px;
                        }
                    }

                    & .history-main-content {
                        width: 100%;
                        color: var(--color-white);

                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        & > p {
                            position: relative;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            font-size: 14px;
                            font-weight: 400;
                            line-height: 40px;
                            line-height: 22px;

                            & .tooltip {
                                position: absolute;
                                top: 0;
                                left: 0;
                                border-radius: 50px;
                                background-color: var(--color-white);
                                padding: 6px 16px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;

                                color: var(--color-black);
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 26px;
                                letter-spacing: -0.16px;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    bottom: -10px;
                                    left: 10%;
                                    transform: translateX(-10%);
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 10px 10px 0 10px;
                                    border-color: var(--color-white) transparent
                                        transparent transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.activity-wrapper {
    width: 100%;
    height: 1080px;
    margin-block: 200px;
    background-color: #a01634;

    & .activity-sub-wrapper {
        width: 100%;
        overflow: hidden;

        & .activity-title-box {
            color: #fff;
            display: flex;
            flex-direction: column;
            gap: 33px;
            padding-left: 64px;
            padding-top: 140px;
            box-sizing: border-box;

            & .activity-title {
                font-size: 100px;
                font-weight: 600;
                line-height: 100px;
                letter-spacing: -3px;
            }

            & .activity-sub-title {
                font-size: 24px;
                font-weight: 600;
                line-height: 34px;
                letter-spacing: -0.24px;
                padding-left: 5px;
            }
        }

        & .activity-slide {
            width: calc(2230px * 2);
            height: 500px;
            margin-top: 120px;
            display: flex;
            gap: 20px;
            animation: slidesketchAni 80s linear infinite;

            & > img {
                width: calc(100% / 2);
                height: 100%;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-drag: none;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .activity-wrapper {
        width: 100%;
        height: 720px;
        margin-block: 0px;
        margin-top: 200px;
        background-color: #a01634;

        & .activity-sub-wrapper {
            width: 100%;
            overflow: hidden;

            & .activity-title-box {
                color: #fff;
                display: flex;
                flex-direction: column;
                gap: 33px;
                padding-left: 34px;
                padding-top: 72px;
                box-sizing: border-box;

                & .activity-title {
                    font-size: 100px;
                    font-weight: 600;
                    line-height: 100px;
                    letter-spacing: -3px;
                }

                & .activity-sub-title {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 34px;
                    letter-spacing: -0.24px;
                    padding-left: 5px;
                }
            }

            & .activity-slide {
                width: calc(1580px * 2);
                height: 350px;
                margin-top: 60px;
                display: flex;
                gap: 20px;
                animation: slidesketchAni 80s linear infinite;

                & > img {
                    width: calc(100% / 2);
                    height: 100%;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-drag: none;
                }
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .activity-wrapper {
        width: 100%;
        height: 676px;
        margin-block: 0px;
        margin-top: 200px;
        background-color: #a01634;

        & .activity-sub-wrapper {
            width: 100%;
            overflow: hidden;

            & .activity-title-box {
                color: #fff;
                display: flex;
                flex-direction: column;
                gap: 33px;
                padding-left: 34px;
                padding-top: 62px;
                box-sizing: border-box;

                & .activity-title {
                    font-size: 100px;
                    font-weight: 600;
                    line-height: 100px;
                    letter-spacing: -3px;
                }

                & .activity-sub-title {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 34px;
                    letter-spacing: -0.24px;
                    padding-left: 5px;
                }
            }

            & .activity-slide {
                width: calc(1480px * 2);
                height: 325px;
                margin-top: 60px;
                display: flex;
                gap: 20px;
                animation: slidesketchAni 80s linear infinite;

                & > img {
                    width: calc(100% / 2);
                    height: 100%;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-drag: none;
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .activity-wrapper {
        width: 100%;
        height: 743px;
        margin-block: 0px;
        margin-top: 50px;
        background-color: #a01634;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .activity-sub-wrapper {
            width: 100%;
            overflow: hidden;

            & .activity-title-box {
                color: #fff;
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-left: 20px;
                padding-top: 0;
                box-sizing: border-box;

                & .activity-title {
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: -0.96px;
                }

                & .activity-sub-title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: -0.14px;
                    padding-left: 5px;
                }
            }

            & .activity-slide {
                width: calc(1480px * 2);
                height: 325px;
                margin-top: 60px;
                display: flex;
                gap: 20px;
                animation: slidesketchAni 80s linear infinite;

                & > img {
                    width: calc(100% / 2);
                    height: 100%;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-drag: none;
                }
            }
        }
    }
}
@media screen and (max-width: 390px) {
    .activity-wrapper {
        width: 100%;
        height: 844px;
        margin-block: 0px;
        margin-top: 50px;
        background-color: #a01634;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .activity-sub-wrapper {
            width: 100%;
            overflow: hidden;

            & .activity-title-box {
                color: #fff;
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-left: 20px;
                padding-top: 0;
                box-sizing: border-box;

                & .activity-title {
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: -0.96px;
                }

                & .activity-sub-title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: -0.14px;
                    padding-left: 5px;
                }
            }

            & .activity-slide {
                width: calc(1480px * 2);
                height: 325px;
                margin-top: 60px;
                display: flex;
                gap: 20px;
                animation: slidesketchAni 80s linear infinite;

                & > img {
                    width: calc(100% / 2);
                    height: 100%;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-drag: none;
                }
            }
        }
    }
}

.clients-wrapper {
    width: 100%;
    height: 100%;

    & .clients-sub-wrapper {
        width: 100%;
        height: 100%;

        display: flex;

        padding: 0 64px;
        padding-bottom: 200px;

        & .clients-main-box {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: end;

            & .clients-logo-box {
                max-width: 1000px;
                width: 100%;
                height: 100%;

                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(4, 1fr);
                gap: 40px;

                & .clients-logo {
                    width: auto;
                    height: 200px;
                    flex-shrink: 0;
                    background-image: url('../../../assets/image/about/clients_background_circle.svg');
                    background-size: auto;
                    background-position: center;
                    background-repeat: no-repeat;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > img {
                        width: auto;
                        height: auto;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .clients-wrapper {
        width: 100%;
        height: 100%;

        & .clients-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;

            padding: 0 64px;
            padding-block: 200px;

            & .clients-main-box {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: end;

                & .clients-logo-box {
                    max-width: 1000px;
                    width: auto;
                    height: auto;

                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(3, 1fr);
                    column-gap: 28px;
                    row-gap: 60px;

                    & .clients-logo {
                        max-width: 160px;
                        width: 160px;
                        height: 160px;
                        flex-shrink: 0;
                        background-image: url('../../../assets/image/about/clients_background_circle.svg');
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & > img {
                            width: auto;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .clients-wrapper {
        width: 100%;
        height: 100%;

        & .clients-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;

            padding: 0 64px;
            padding-block: 200px;

            & .clients-main-box {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: start;

                & .clients-logo-box {
                    max-width: 1000px;
                    width: auto;
                    height: auto;
                    margin-top: 60px;

                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(3, 1fr);
                    column-gap: 28px;
                    row-gap: 60px;

                    & .clients-logo {
                        max-width: 160px;
                        width: 160px;
                        height: 160px;
                        flex-shrink: 0;
                        background-image: url('../../../assets/image/about/clients_background_circle.svg');
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & > img {
                            width: auto;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 745px) {
    .clients-wrapper {
        width: 100%;
        height: 100%;

        & .clients-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;

            padding: 0 20px;
            padding-block: 200px;

            & .clients-main-box {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: start;

                & .clients-logo-box {
                    max-width: 1000px;
                    width: auto;
                    height: auto;
                    margin-top: 60px;

                    /* display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: repeat(4, 1fr); */

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    column-gap: 20px;
                    row-gap: 32px;

                    & .clients-logo {
                        max-width: 160px;
                        width: 160px;
                        height: 160px;
                        flex-shrink: 0;
                        background-image: url('../../../assets/image/about/clients_background_circle.svg');
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & > img {
                            width: auto;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 386px) {
    .clients-wrapper {
        width: 100%;
        height: 100%;

        & .clients-sub-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;

            padding: 0 20px;
            padding-block: 200px;

            & .clients-main-box {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: start;

                & .clients-logo-box {
                    max-width: 1000px;
                    width: auto;
                    height: auto;
                    margin-top: 60px;

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    column-gap: 20px;
                    row-gap: 32px;

                    & .clients-logo {
                        max-width: 135px;
                        width: 135px;
                        height: 135px;
                        flex-shrink: 0;
                        background-image: url('../../../assets/image/about/clients_background_circle.svg');
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & > img {
                            width: auto;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

@keyframes slidesketchAni {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
    background-color: #fff !important;
    background: #fff !important;
    border-radius: 50% !important;
    transform: scale(1) !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    width: 12px !important;
    height: 12px !important;
    transform: scale(1) !important;
}
